import { Link, NavLink } from "react-router-dom";
import { mainRoutes } from "index";

import "./header.css";

export function Header() {
  return (
    <header className="yp-header">
      <div className="yp-logo">
        <Link to="/portfolio" />
      </div>
      <nav className="yp-navigation">
        {mainRoutes.map(({ id, path }) => (
          <NavLink
            key={id}
            className={({ isActive }) =>
              isActive
                ? "yp-navigation-link yp-navigation-link--active"
                : "yp-navigation-link"
            }
            to={path}
          >
            {id}
          </NavLink>
        ))}
      </nav>
    </header>
  );
}
