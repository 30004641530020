import { Layout } from "components/layout/layout";
import { CaseStudyBanner } from "components/case-study-bunner/case-study-banner";
import { CaseStudyNavigation } from "components/case-study-navigation/case-study-navigation";

import "./styles/case-study.css";

export function UxSystemCaseStudy() {
  return (
    <Layout background="light">
      <CaseStudyBanner name="finTech">
        <h1 className="yp-case-study-banner-head">
          UX/UI case for financial system
        </h1>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Industry:</span>{" "}
          fintech.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">The role:</span>{" "}
          UX/System Designer.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">
            Implementation time:
          </span>{" "}
          40 weeks.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Technology:</span>{" "}
          react JS+Node.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Description:</span>{" "}
          financial ERP system that manage loans, flow «Servicing and
          Post-Close», design system.
        </span>
      </CaseStudyBanner>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">The product overview</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The ready system aimed to provide users with financial services and
          loans with the help of intelligent FinTech technologies. It is a
          versatile platform capable of ensuring high trust for companies,
          financial service providers, and end users. The main objective of this
          project is to create a software solution that offers easy, fast loans
          of large amounts, financial transactions, support, and safety/security
          guarantees for all parties involved.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-48">
          The project workflow was based on the{" "}
          <span className="yp-case-study-text--bold">
            Human-Centered Design and Design Thinking methodologies
          </span>
          . In the planning phase, we realized that the most effective strategy
          for the design stage would be teamwork with a clear delineation of the
          task pool. An extended team of designers worked on this project in
          close connection; the implementation of the entire design cycle was
          dispersed among the performers.
        </p>
        <h2 className="yp-case-study-head">Wireframing</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          High-fidelity wireframes formed the basis of the requirements for my
          part of work on the project. General research, a database of user
          responses, low-fidelity skeletons, and paper prototypes were already
          completed thanks to the well-coordinated work of the design team. The
          result of the sprints with my participation was a part of the
          wireframes, based on which it was possible to trace the user’s flow
          with different scenarios. Examples of wireframes are below:
        </p>
        <div className="yp-case-study--fintech-image-1 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Styleguide and design system</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The main task of the visual design was to create a design system
          capable of the platform’s flexibility and adaptability. Here, we
          decided to move away from the traditional UI design and go the way of
          typesetting. Therefore, we created a bundle of design elements, with
          the help of which developers could quickly «decorate» the system and
          give it a finished look.
        </p>
        <div className="yp-case-study--fintech-image-2 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--fintech-image-3 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--fintech-image-4 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Linked prototype</h2>
        <div className="yp-case-study--fintech-image-5 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--fintech-image-6 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Summing up</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-48">
          This project is an excellent example of designers’ teamwork. Success
          in product implementation lies in a well-coordinated, delimited flow,
          where each designer has a pool of tasks. At the same time, each team
          player cannot show the best result without the participation of
          colleagues. We managed to deliver the project on time with a minimum
          number of edits, which, in turn, allowed the development team to build
          the product in the subsequent iterations effectively.
        </p>
      </div>
      <CaseStudyNavigation
        prevLink="/portfolio/case-study/ux"
        nextLink="/portfolio/case-study/presale-estimate"
        prevLinkTitle="UX CASE FOR HEALTH APP"
        nextLinkTitle="CASE PRESALE EXPERTISE"
      />
    </Layout>
  );
}
