import "./footer.css";

export function Footer() {
  const year = new Date().getFullYear();

  return (
    <footer className="yp-footer">
      &copy; {year} YANA BICHEVA. All Rights Reserved.
    </footer>
  );
}
