import { Layout } from "components/layout/layout";
// todo: rename
import { CaseStudyBanner } from "components/case-study-bunner/case-study-banner";
import { CaseStudyNavigation } from "components/case-study-navigation/case-study-navigation";
import { Carousel } from "components/carousel/carousel";

import persona1 from "assets/images/persona-1.png";
import persona2 from "assets/images/persona-2.png";

import "./styles/case-study.css";

export function UxCaseStudy() {
  return (
    <Layout background="light">
      <CaseStudyBanner name="health">
        <h1 className="yp-case-study-banner-head">UX case for health app</h1>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Industry:</span>{" "}
          health and wellness.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">The role:</span> UX
          Designer.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">
            Implementation time:
          </span>{" "}
          12 weeks.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Technology:</span>{" "}
          crossplatform app (Flutter).
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Description:</span>{" "}
          the application helps the user quickly find a doctor and get the
          necessary consultation online. Flow «Patient».
        </span>
      </CaseStudyBanner>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">The product overview</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          2020 brought many changes due to a new phenomenon: the coronavirus
          pandemic. Users of healthcare services faced a big problem: the lack
          of free time for doctors, the danger of going to hospitals, and
          restrictions on contact with people. Another essential issue was
          peoples’ mental health: many recoveries complained of mental
          health-related symptoms. Some people got through self-isolation so
          hard that they eventually needed the help of a
          doctor/psychoanalyst/coach.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-48">
          The stakeholders of this project decided to solve this problem quickly
          and efficiently by creating a mobile platform for quick communication
          with mental health specialists.
        </p>
        <h2 className="yp-case-study-head">Investigations</h2>
        <div className="yp-case-study-two-columns-block yp-case-study-text--margin-bottom-34">
          <div className="yp-case-study--health-image-1" />
          <div>
            <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
              The entire project is based on{" "}
              <span className="yp-case-study-text--bold">
                Design Thinking and Human-Centered Design methodologies
              </span>
              . Guided by the principles of these methodologies, we could
              identify the weaknesses of similar applications at the stage of
              competitor analysis.
            </p>
            <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
              The most striking remark was that most of the apps on the market
              are guided by the «call a specialist» system, making no
              distinction between the target audience and professions. For
              example, in the place of a specialist named doctor, there could be
              any other specialist: a hairdresser, a mechanic, a driver, etc.;
              applications retain their structure regardless of the user’s
              choice.
            </p>
            <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
              It prompted us to create{" "}
              <span className="yp-case-study-text--bold">
                the basic artifacts of the research stage
              </span>
              :
            </p>
            <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
              - <span className="yp-case-study-text--bold">User Persona</span>{" "}
              to understand WHO our users are;
            </p>
            <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
              -{" "}
              <span className="yp-case-study-text--bold">
                Customer Journey Map
              </span>{" "}
              to understand WHAT our users need and what opportunities we have.
            </p>
          </div>
        </div>
        <div className="yp-case-study-slider yp-case-study-text--margin-bottom-24">
          <Carousel images={[persona1, persona2]} />
        </div>
        <div className="yp-case-study--health-image-2 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Detalization</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          At the research stage, we found out the wishes, goals, doubts, and
          fears of the app’s users.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          We have identified a fundamental principle: from the first interaction
          with the screen, the user should feel confident and calm and realize
          that the professionals are here and will quickly help him. It helped
          us build the{" "}
          <span className="yp-case-study-text--bold">
            App flow and User story
          </span>
          .
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          We modified the traditional patient questionnaire asked to fill out
          before a visit to the therapist because the first thing users want to
          do is explain what happened to them and why they need assistance. So
          we’ve made a flow chatbot as an onboarding that gets to know the user
          and asks him all the important questions.
        </p>
        <div className="yp-case-study--health-image-3 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--health-image-4 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Wireframing</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The existing artifacts allowed us to start implementing our ideas as
          paper sketches, where we identified the most suitable and appropriate
          technical structure for navigating the application. Unfortunately, the
          drawings and photos have not been preserved.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          As a UX designer, I prefer to work on high-fidelity wireframes. It
          allows the development team to work out the application from different
          angles at the prototyping stage, considering all the points that were
          identified in the previous steps. This approach also greatly
          simplifies the task at the UI design stage.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          And more, it is very convenient for working with different teams. The
          development team could already evaluate the future work scope, so the
          task of business analysts and project managers becomes much more
          straightforward. At the same time, stakeholders don’t have to wait
          long to see tangible results from the design team.
        </p>
        <div className="yp-case-study--health-image-5 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">
          Full flow high-fidelity wireframes
        </h2>
        <div className="yp-case-study--health-image-6 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Summing up</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          My task on this project was the detailed UX development of the
          «Patient» flow. I was the person in charge of the entire UX process at
          this stage. Successful product development results from the work of a
          large team of professionals I was lucky to be a part of.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-48">
          Detailed attention to design principles has allowed us to successfully
          get milestones, meet deadlines, meet the requirements of users,
          stakeholders and technical specialists, not to be stressed out, and
          enjoy working on a project.
        </p>
      </div>
      <CaseStudyNavigation
        prevLink="/portfolio/case-study/ux-ui"
        nextLink="/portfolio/case-study/ux-system"
        prevLinkTitle="UX/UI CASE FOR SPORTS APP"
        nextLinkTitle="UX/UI CASE FOR FINANCIAL SYSTEM"
      />
    </Layout>
  );
}
