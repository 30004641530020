import { CaseStudyNavigation } from "components/case-study-navigation/case-study-navigation";

export function GeneralTab() {
  return (
    <>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">The product overview</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          Improving communication and understanding with clients is the main
          issue that went into creating the concept of this project. A customer
          needs to grow the business and make it more sophisticated as the
          environment changes and needs to be constantly re-adjusted. The
          project’s main task is to redefine communication with the client using
          digital markers, such as NFC Tags, QR codes, RFID, GS1 Digital link,
          etc.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The basis of the project is to open up new opportunities for customers
          (via their smartphones) and companies (through a flexible platform and
          software ecosystem). Regardless of the goal scale, they will be
          achieved — whether it is a restaurant visit or building a logistics
          system for goods with smart packaging.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The top enlarged Project Structure looks straightforward. The main
          product is a self-service platform that is connected to the iOS app.
          Web applications and mini-games are also linked, so users can
          configure settings and create a web application within the system.
        </p>
        <div className="yp-case-study--product-total-scheme yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Investigations</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          This project is based on Human-centered design, design thinking, and
          Agile methodology. However, we didn’t use the Agile approach from the
          start: initially, the work processes were similar to the Waterfall
          implementation system. The challenge of moving to a more effective
          work system was the absence of a business analyst and (often) a
          project manager. In this regard, the development team represented by
          the product designer and solution architect made planning decisions on
          the project.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          We started product development with flow systems and their individual
          parts to better understand the relationship between separate parts of
          the project.
        </p>
        <div className="yp-case-study--product-scheme-redesigned yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--product-multipack yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--product-container yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--product-modules yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--product-builder yp-case-study-text--margin-bottom-24" />
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          After research, the final flow diagram of the architecture of several
          products’ interconnection looked this way:
        </p>
        <div className="yp-case-study--product-total-scheme-2 yp-case-study-text--margin-bottom-24" />
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          Relationship of products:
        </p>
        <div className="yp-case-study--product-devices-scheme yp-case-study-text--margin-bottom-24" />
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-48">
          To take a closer look at each of the sub-products of the project, I
          divided the case study into several parts. Each of them included an
          entire design cycle from scratch to production.
        </p>
      </div>
      <CaseStudyNavigation
        prevLink="/portfolio/case-study/presale-estimate"
        nextLink="/portfolio/case-study/product-design/self-service-platform"
        prevLinkTitle="PRESALE EXPERTISE"
        nextLinkTitle="CASE SELF-SERVICE PLATFORM"
      />
    </>
  );
}
