import { CaseStudyNavigation } from "components/case-study-navigation/case-study-navigation";

export function IOSAppTab() {
  return (
    <>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">The product overview</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The iOS application was a mandatory and necessary link in the
          implementation of the entire project. The app aimed to collect data
          into the general system of the self-service platform.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The connection between the ecosystem and the user could be made via
          phone at several project stages. With the help of labels accounting in
          various ways, we created and filled in the databases of NFC, R-FID,
          and QR codes in the platform.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The main requirements for the application were ease of use, interface
          clearness, and appearance of the utility: a technical tool that should
          not evoke emotions but solve the assigned tasks. One of the business
          requirements was the visualization of complex and slightly routined
          warehouse operations to minimize human factor errors. In this regard,
          the application has unique graphics at the stage of tag accounting.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The main technical requirement for the application was productivity
          and operating speed. The app should instantly show users that they are
          proceeding correctly and all the data is securely placed in the
          overall product system. In this regard, the user sees progress and a
          clear result at each data processing stage. After several sessions of
          requirements clarification, we were able to create an application
          structure that formed the basis of the wireframes and design.
        </p>
        <div className="yp-case-study--product-ios-flow yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Wireframes</h2>
        <div className="yp-case-study--product-ios-wireframes yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">UI Design</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          In this case, a distinctive feature of the design was the creation of
          dark and light application themes. The reason for this necessity was
          that the external conditions for operating the app by the user could
          vary a lot: there are many lighting modes at the warehouse, workshop,
          street unloading, port, etc. Therefore, for the user’s convenience,
          we’ve not only made light and dark app versions but also included such
          an essential (albeit prominent) feature as a flashlight.
        </p>
        <div className="yp-case-study--product-ios-styleguide yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--product-ios-design yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--product-ios-design-2 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Linked prototype</h2>
        <div className="yp-case-study--product-ios-prototype yp-case-study-text--margin-bottom-48" />
      </div>
      <CaseStudyNavigation
        prevLink="/portfolio/case-study/product-design/self-service-platform"
        nextLink="/portfolio/case-study/product-design/website"
        prevLinkTitle="CASE SELF-SERVICE PLATFORM"
        nextLinkTitle="CASE WEBSITE"
      />
    </>
  );
}
