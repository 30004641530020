import { Banner } from "components/banner/banner";
import { Layout } from "components/layout/layout";

import "./styles/art.css";

export function Art() {
  return (
    <Layout background="dark">
      <Banner name="art">
        Drawing lines and dots is the best way for me to relax, switch,
        meditate, and recharge
      </Banner>
      <div className="yp-art">
        <div className="yp-art-image yp-art-image-1" />
        <div className="yp-art-image yp-art-image-2" />
        <div className="yp-art-image yp-art-image-3" />
        <div className="yp-art-image yp-art-image-4" />
        <div className="yp-art-image yp-art-image-5" />
        <div className="yp-art-image yp-art-image-6" />
      </div>
    </Layout>
  );
}
