type AssetsManifest = {
  files: Record<string, string>;
  entrypoints: string[];
};

function getOrigin() {
  const { host, protocol } = window.location;
  return `${protocol}//${host}`;
}

function trimPathPrefix(path: string) {
  const matchList = path.match(/(static.*)/g);
  return matchList ? matchList[0] : "";
}

function isPathToAsset(path: string, extensions: string[]) {
  return extensions.some((ext) => path.includes(ext));
}

function createAssetsPathList(
  assetsMap: Record<string, string>,
  extensions: string[]
) {
  return Object.values(assetsMap)
    .map((path) =>
      isPathToAsset(path, extensions) ? trimPathPrefix(path) : ""
    )
    .filter((path) => path);
}

export function loadAssets() {
  return new Promise((resolve) => {
    const baseUrl = getOrigin();

    fetch(`${baseUrl}/asset-manifest.json`)
      .then((res) => res.json())
      .then(async ({ files: paths }: AssetsManifest) => {
        const fontPathList = createAssetsPathList(paths, [".ttf"]);

        await Promise.all(
          fontPathList.map(
            (path) =>
              new Promise((resolve) => {
                const link = document.createElement("link");

                link.rel = "preload";
                link.href = `${baseUrl}/${path}`;
                link.as = "font";
                link.type = "font/ttf";

                document.head.appendChild(link);

                resolve(true);
              })
          )
        );

        const imagePathList = createAssetsPathList(paths, [
          ".svg",
          ".png",
          ".jpg",
          ".webp",
        ]);

        const head = document.querySelector("head");
        const progress = document.getElementById("progress");
        let loadedImagesCount = 1;

        return Promise.all(
          imagePathList.map(
            (path) =>
              new Promise((resolve) => {
                const link = document.createElement("link");
                link.rel = "preload";
                link.as = "image";
                link.href = `${baseUrl}/${path}`;

                head!.appendChild(link);
                link.onload = () => {
                  loadedImagesCount = loadedImagesCount + 1;
                  progress!.innerText = `${Math.floor(
                    (loadedImagesCount * 100) / imagePathList.length
                  )}`;
                  resolve(true);
                };
              })
          )
        );
      })
      .then(() => setTimeout(() => resolve(true), 500));
  });
}
