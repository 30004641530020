import React from "react";
import { NavLink } from "react-router-dom";
import { Layout } from "components/layout/layout";
import { CaseStudyBanner } from "components/case-study-bunner/case-study-banner";

import "./styles/case-study.css";
import "./styles/tabs.css";

type ProductDesignCaseStudyProps = {
  children: React.ReactNode;
};

const tabs = [
  { title: "General", path: "/portfolio/case-study/product-design/general" },
  {
    title: "Self-service platform",
    path: "/portfolio/case-study/product-design/self-service-platform",
  },
  { title: "iOS app", path: "/portfolio/case-study/product-design/ios-app" },
  { title: "Website", path: "/portfolio/case-study/product-design/website" },
  { title: "Web apps", path: "/portfolio/case-study/product-design/web-apps" },
];

export function ProductDesignCaseStudy({
  children,
}: ProductDesignCaseStudyProps) {
  return (
    <Layout background="light">
      <CaseStudyBanner name="productDesign">
        <h1 className="yp-case-study-banner-head">
          Product design case for startup
        </h1>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Industry:</span>{" "}
          Logistics / NFC Tag ecosystem.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">The role:</span>{" "}
          Product Designer.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">
            Implementation time:
          </span>{" "}
          2,5 years.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Technology:</span>{" "}
          React JS+Node, iOS native, web.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Description:</span>{" "}
          <a
            className="yp-case-study-link"
            href="https://www.tapwow.net"
            target="blank"
          >
            tapwow.net
          </a>
          <br />
          <a
            className="yp-case-study-link"
            href="https://apps.apple.com/tt/app/dirx2/id1497933863"
            target="blank"
          >
            apps.apple.com/tt/app/dirx2/id1497933863
          </a>
          <br />
          <a
            className="yp-case-study-link"
            href="https://apps.apple.com/tt/app/tapwow-scanner/id1504708640"
            target="blank"
          >
            apps.apple.com/tt/app/tapwow-scanner/id1504708640
          </a>
        </span>
      </CaseStudyBanner>
      {/* todo: add bottom line */}
      <div className="yp-tabs">
        <div className="yp-tabs-wrapper">
          {tabs.map(({ title, path }) => (
            <NavLink
              key={path}
              className={({ isActive }) =>
                isActive ? "yp-tab yp-tab--active" : "yp-tab"
              }
              to={path}
            >
              {title}
            </NavLink>
          ))}
        </div>
        {children}
      </div>
    </Layout>
  );
}
