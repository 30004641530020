import { useState } from "react";

import "./carousel.css";

type CarouselProps = {
  images: string[];
};

export function Carousel({ images }: CarouselProps) {
  const [index, setIndex] = useState(0);

  const onPrev = () => {
    if (index === 0) {
      return setIndex(images.length - 1);
    }

    setIndex((prev) => prev - 1);
  };

  const onNext = () => {
    const nextIndex = index + 1;
    const hasNextImage = images[nextIndex];

    if (!hasNextImage) {
      return setIndex(0);
    }

    setIndex(nextIndex);
  };

  return (
    <div className="yp-carousel">
      {images.length > 1 ? (
        <button
          className="yp-carousel-button yp-carousel-button--left"
          onClick={onPrev}
        />
      ) : null}

      <div
        className="yp-carousel-image"
        style={{
          backgroundImage: `url(${images[index]})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
      {images.length > 1 ? (
        <button
          className="yp-carousel-button yp-carousel-button--right"
          onClick={onNext}
        />
      ) : null}
    </div>
  );
}
