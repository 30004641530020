import { Banner } from "components/banner/banner";
import { Layout } from "components/layout/layout";

import "./styles/about.css";

export function About() {
  return (
    <Layout background="dark">
      <Banner name="about">A few words more about me</Banner>
      <div className="yp-about">
        <p className="yp-about-text">
          It is a short essay to get you acquainted with me better and, perhaps,
          to anticipate some questions.
        </p>
        <p className="yp-about-text yp-about-text--margin-top-16">
          I’m Yana, I am 33 years old, and I am from Kharkiv, Ukraine. In the
          fall of 2021, my husband and I decided to try a new experience: living
          abroad. We had not lived in other countries for more than two weeks
          before, so the idea seemed horizon-opening and fascinating to us. My
          husband received a job offer from an Estonian IT company and we
          settled in Tallinn at the end of November 2021. Having visited Estonia
          before (on vacation), I was excited about the opportunity to live
          here. I was delighted with the magnificent nature, friendly
          environment, delicious cuisine, and everything that creates a unique
          atmosphere in this country. I quit my previous job and took a short
          break to switch, redirect my thoughts, and arrange life in a new
          place.
        </p>
        <div className="yp-about-divider" />
        <p className="yp-about-text">
          I successfully coped with the challenge of relocation, but the
          vacation went out of plan. On February 24, 2022, the life of many,
          including me, turned upside down. Like all the people supporting
          Ukraine, I was involved in helping and rescuing relatives and friends
          trapped in the war zone.
        </p>
        <div className="yp-about-divider" />
        <p className="yp-about-text">
          To avoid getting hung up on bad events, I plunged into my activities
          deeper: I drew, mastered new aspects of design, and explored my
          profession and the country I live in. My sources of inspiration helped
          me cope with difficulties; now, I know for sure the main thing is not
          to dwell on the bad. I remain a pacifist and believe that war is a
          mortal sin, but life is the most beautiful phenomenon of nature, and
          after dark and gloomy days, bright and joyful happiness always comes.
        </p>
        <p className="yp-about-text yp-about-text--margin-top-38">
          I hope you’ll enjoy browsing this site, and I look forward to any
          feedback from you!
        </p>
        <div className="yp-about-divider" />
      </div>
    </Layout>
  );
}
