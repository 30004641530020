import { Link } from "react-router-dom";
import { Layout } from "components/layout/layout";
import { Banner } from "components/banner/banner";
import { caseStudyRoutes } from "index";

import "./styles/portfolio.css";

export function Portfolio() {
  const portfolioRoutes = caseStudyRoutes.filter(({ onMain }) => onMain);

  return (
    <Layout background="dark">
      <Banner name="portfolio">
        Hello! My name is Yana and this is my portfolio website
      </Banner>
      <div className="yp-portfolio-tiles yp-design-image-cubes--portfolio-page yp-design-image-cubes">
        <div className="yp-portfolio-tile yp-portfolio-tile--1">
          <Link to={portfolioRoutes[0].path} />
        </div>
        <div className="yp-portfolio-tile yp-portfolio-tile--2">
          <Link to={portfolioRoutes[1].path} />
        </div>
        <div className="yp-portfolio-tile yp-portfolio-full-size-tile yp-portfolio-tile--3">
          <Link to={portfolioRoutes[2].path} />
        </div>
        <div className="yp-portfolio-tile yp-portfolio-tile--4">
          <Link to={portfolioRoutes[3].path} />
        </div>
        <div className="yp-portfolio-tile yp-portfolio-tile--5">
          <Link to={portfolioRoutes[4].path} />
        </div>
      </div>
    </Layout>
  );
}
