import { Layout } from "components/layout/layout";
import { CaseStudyBanner } from "components/case-study-bunner/case-study-banner";
import { CaseStudyNavigation } from "components/case-study-navigation/case-study-navigation";

import "./styles/case-study.css";

export function UxUiCaseStudy() {
  return (
    <Layout background="light">
      <CaseStudyBanner name="stepTribe">
        <h1 className="yp-case-study-banner-head">UX/UI case for sports app</h1>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Industry:</span>{" "}
          Sport and tourism.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">The role:</span>{" "}
          UX/UI Designer.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">
            Implementation time:
          </span>{" "}
          24 weeks.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Technology:</span>{" "}
          crossplatform app (Flutter).
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Description:</span>{" "}
          <a
            className="yp-case-study-link"
            href="https://steptribe.io"
            target="blank"
          >
            steptribe.io
          </a>
        </span>
      </CaseStudyBanner>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">The product overview</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          Sports and a healthy lifestyle are part of people’s lives; however,
          not everyone enjoys exercising in gyms on exercise programs. Many
          people appreciate an active lifestyle, are engaged in tourism and love
          tracking and hiking. But, during the lockdown, it has become much
          harder for them to fulfill these needs.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-48">
          The application developed allows such people to go through distances
          in groups and teams, organize competitions, and have fun by setting
          analogs of exciting routes. Thanks to the app, users can reproduce
          temporarily unavailable impressions even being in their own city. They
          can walk even in circles with persons who are physically far away: the
          application helps people have fun by comparing the distance with known
          routes.
        </p>
        <h2 className="yp-case-study-head">Investigations</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The project is based on the{" "}
          <span className="yp-case-study-text--bold">
            Lean Design methodology
          </span>
          . In this case, the principles of this approach ideally combined all
          the initial information, user insights, and client requirements.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The product owners already knew who the users and their needs were: it
          was a distinctive feature of the project. After user investigations,
          the core points have been identified:
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - People involved: families, friends, or colleagues;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Geographic location: various (users can train in different places);
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Ability to create a team to facilitate team spirit by completing a
          challenge together;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Aim to improve users’ health and well-being;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Ability to support charities through fundraising is essential.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          <span className="yp-case-study-text--bold">Competitor analysis</span>{" "}
          showed that in no application on the market, there is a symbiosis of
          our application’s suggested features. It served as a green light for
          further work on the app and the Proof of Concept stage.
        </p>
        <div className="yp-case-study--steptribe-image-1 yp-case-study-text--margin-bottom-24" />
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          An essential part of the study was the testing of hypotheses. We’ve
          identified the main directions of the future POC stage and formed
          hypotheses that need to be tested to create a future flow application.
          It was crucial for us to evaluate the app’s main features since we
          laid the foundation for the future MVP stage at the hypothesis testing
          stage. Our initial questions were broad:
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - What happens when people can’t travel?
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Do you have groups of people in different parts of the world?
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Do you want to create your unique event?
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - How can you show your supporters how you and your team are
          progressing on your challenge?
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The formulation of hypotheses allowed us to concentrate on the
          essentials, where questions turned into specific tasks for solving
          user problems.
        </p>
        <div className="yp-case-study--steptribe-image-2 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Proof of concept (POC)</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          Hypothesis testing, the possibility of technical implementation, and
          the finalization of the concept took place with the help of the POC
          stage. It allows us to confirm or disprove the hypothesis, as well as
          to evaluate its effectiveness. Along with the development team and
          project stakeholders, a workflow was arranged, and several screens
          were chosen to show the product’s viability.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The functionality was limited to key features that allowed us to
          implement the concept in a short time, achieving saving time, budget,
          and production capacity on less critical functionality for the
          product.
        </p>
        <div className="yp-case-study--steptribe-image-3 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--steptribe-image-4 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Minimum Valuable Product (MVP)</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The MVP stage was the central part of a project and included MVP
          wireframes and UI design. After checking the POC version and ensuring
          that our hypotheses were viable, we started working on a more advanced
          version of the application, including the broader functionality. The
          extended user flow is the foundation for system design and frameworks
          for this product version.
        </p>
        <div className="yp-case-study--steptribe-image-5 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">
          Full flow high-fidelity wireframes
        </h2>
        <div className="yp-case-study--steptribe-image-6 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--steptribe-image-7 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Linked prototype</h2>
        <div className="yp-case-study--steptribe-image-8 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--steptribe-image-9" />
      </div>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">UI Design and graphics</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The UI design stage began with a survey of a group of users and
          stakeholders. The main question at this stage is HOW do you see the
          future application? The answers were fascinating and varied, but all
          respondents agreed that the design should be bright, memorable, and
          entertaining.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          In turn, the stakeholders expressed their clear desire to see the
          application in blue and yellow colors. After providing several design
          concepts, we were convinced that the combination of blue and yellow
          was the most suitable option for the app.
        </p>
        <div className="yp-case-study--steptribe-image-10 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">UI Design variants</h2>
        <div className="yp-case-study--steptribe-image-11 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">UI Design final variant</h2>
        <div className="yp-case-study--steptribe-image-12 yp-case-study-text--margin-bottom-48" />
        <div className="yp-case-study--steptribe-image-13 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Styleguide</h2>
        <div className="yp-case-study--steptribe-image-14 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Logo Design</h2>
        <div className="yp-case-study--steptribe-image-15 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Another examples of use</h2>
        <div className="yp-case-study--steptribe-image-16 yp-case-study-text--margin-bottom-24" />
      </div>
      <div className="yp-case-study">
        <div className="yp-case-study--steptribe-image-17" />
      </div>
      <div className="yp-case-study yp-case-study-text--margin-bottom-72">
        <h2 className="yp-case-study-head">Summing up</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          This project was implemented in the MVP version using the Lean Design
          approach. Starting the project from scratch, as a Designer, I led the
          project through the main stages of the methodology, which allowed me
          and the whole team to be flexible to the requirements of clients,
          while not infringing on the requirements and desires of users. The
          main goals of users are realized through a fast and understandable
          flow of the application. A bright and memorable design also covers the
          needs of users that we identified before the start of the UI stage.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          My role on this project included a full design cycle, from concept
          development and hypotheses to the application logo, which allowed me
          to fully immerse myself in the project and realize all the
          possibilities. Close collaboration with the development department
          allowed me to keep my finger on the pulse and create a design that
          satisfies the needs of all parties of the project.
        </p>
        <p className="yp-case-study-text">Thanks for your attention.</p>
      </div>
      <CaseStudyNavigation
        prevLink="/portfolio/case-study/product-design/web-apps"
        nextLink="/portfolio/case-study/ux"
        prevLinkTitle="CASE WEB APPS"
        nextLinkTitle="UX CASE FOR HEALTH APP"
      />
    </Layout>
  );
}
