import { ReactNode } from "react";

import "./case-study-banner.css";

type CaseStudyBannerProps = {
  name: "finTech" | "preSale" | "health" | "stepTribe" | "productDesign";
  children: ReactNode | ReactNode[];
};

export function CaseStudyBanner({ name, children }: CaseStudyBannerProps) {
  return (
    <div className={`yp-case-study-banner yp-case-study-banner--${name}`}>
      <div className="yp-case-study-banner-description">{children}</div>
    </div>
  );
}
