import { Banner } from "components/banner/banner";
import { Layout } from "components/layout/layout";
import { Carousel } from "components/carousel/carousel";
import designPro1 from "assets/images/design-pro-1.png";
import designPro2 from "assets/images/design-pro-2.png";
import designPro3 from "assets/images/design-pro-3.png";
import designPro4 from "assets/images/design-pro-4.png";
import chooseAProfession from "assets/images/choose-a-profession.png";
import springFest1 from "assets/images/spring-fest-1.png";
import springFest2 from "assets/images/spring-fest-2.png";
import springFest3 from "assets/images/spring-fest-3.png";
import businessTrip1 from "assets/images/business-trip-1.png";
import businessTrip2 from "assets/images/business-trip-2.png";
import expertsDay1 from "assets/images/experts-day-1.png";
import expertsDay2 from "assets/images/experts-day-2.png";
import expertsDay3 from "assets/images/experts-day-3.png";

import "./styles/activities.css";

export function Activities() {
  return (
    <Layout background="dark">
      <Banner name="activities">
        Conferences, business trips, and charity lectures are also part of my
        professional experience
      </Banner>
      <div className="yp-activities">
        <div className="yp-activity yp-activity--ltr">
          <div className="yp-activity-photo">
            <Carousel
              images={[designPro1, designPro2, designPro3, designPro4]}
            />
          </div>
          <div className="yp-activity-description">
            <p className="yp-activity-text yp-activity-text--bullet">
              <span className="yp-activity--bold-text">Conference:</span> Design
              PRO Conference.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Date: </span>June 2019.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Location: </span>Kharkiv,
              Ukraine.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">The role: </span>Speaker.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Report topic: </span>
              Augmented and Mixed reality UX.
            </p>
          </div>
        </div>

        <div className="yp-activity yp-activity--rtl">
          <div className="yp-activity-photo">
            <Carousel images={[chooseAProfession]} />
          </div>
          <div className="yp-activity-description">
            <p className="yp-activity-text yp-activity-text--bullet">
              <span className="yp-activity--bold-text">Conference: </span>Choose
              a profession workshop for for orphans and teenagers from the
              DONBASS region.
            </p>
            <p className="yp-activity-text">
              {/* todo: check date */}
              <span className="yp-activity--bold-text">Date: </span>June 2019.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Location: </span>Kharkiv,
              Ukraine.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">The role: </span>Work
              shop trainer.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Report topic: </span>What
              is UI/UX design on the example of Instagram app.
            </p>
          </div>
        </div>

        <div className="yp-activity yp-activity--ltr">
          <div className="yp-activity-photo">
            <Carousel images={[springFest1, springFest2, springFest3]} />
          </div>
          <div className="yp-activity-description">
            <p className="yp-activity-text yp-activity-text--bullet">
              <span className="yp-activity--bold-text">Conference: </span>PM and
              Design Spring Fest.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Date: </span>March 2018.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Location: </span>
              Mykolaiv, Ukraine.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">The role: </span>Speaker.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Report topic: </span>UX
              Design.
            </p>
          </div>
        </div>

        <div className="yp-activity yp-activity--rtl">
          <div className="yp-activity-photo">
            <Carousel images={[businessTrip1, businessTrip2]} />
          </div>
          <div className="yp-activity-description">
            <p className="yp-activity-text yp-activity-text--bullet">
              <span className="yp-activity--bold-text">Business trip: </span>
              Research, investigation and users interview on fields.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Date: </span>August 2017.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Location: </span>Vienne,
              Austria; Dusseldorf, Germany.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">The role: </span>UX
              Designer.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Goal: </span>Find out the
              needs of users who work in workshops in metal rolling plants.
            </p>
          </div>
        </div>

        <div className="yp-activity yp-activity--ltr">
          <div className="yp-activity-photo">
            <Carousel images={[expertsDay1, expertsDay2, expertsDay3]} />
          </div>
          <div className="yp-activity-description">
            <p className="yp-activity-text yp-activity-text--bullet">
              <span className="yp-activity--bold-text">Conference: </span>Design
              Experts Day.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Date: </span>October
              2016.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Location: </span>Kharkiv,
              Ukraine.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">The role: </span>Speaker.
            </p>
            <p className="yp-activity-text">
              <span className="yp-activity--bold-text">Report topic: </span>
              Mentor and mantee in UI/UX Design.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
