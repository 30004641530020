import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { Portfolio } from "pages/portfolio";
import { CV } from "pages/cv";
import { Activities } from "pages/activities";
import { Art } from "pages/art";
import { About } from "pages/about";
import { UxUiCaseStudy } from "pages/ux-ui-case-study";
import { UxCaseStudy } from "pages/ux-case-study";
import { ProductDesignCaseStudy } from "pages/product-design-case-study";
import { UxSystemCaseStudy } from "pages/ux-system-case-study";
import { PreSaleCaseStudy } from "pages/presale-case-study";
import { GeneralTab } from "components/product-design-case-study-tabs/general";
import { SelfServicePlatformTab } from "components/product-design-case-study-tabs/self-service-platform";
import { IOSAppTab } from "components/product-design-case-study-tabs/ios-app";
import { WebsiteTab } from "components/product-design-case-study-tabs/website";
import { WebAppsTab } from "components/product-design-case-study-tabs/web-apps";
import { loadAssets } from "./utils/utils";

import "normalize.css";
import "./index.css";

export const mainRoutes = [
  {
    id: "Portfolio",
    path: "/portfolio",
    element: <Portfolio />,
  },
  {
    id: "CV",
    path: "/cv",
    element: <CV />,
  },
  {
    id: "Activities",
    path: "/activities",
    element: <Activities />,
  },
  {
    id: "Art",
    path: "/art",
    element: <Art />,
  },
  {
    id: "About",
    path: "/about",
    element: <About />,
  },
];

export const caseStudyRoutes = [
  {
    onMain: true,
    path: "/portfolio/case-study/ux-ui",
    element: <UxUiCaseStudy />,
  },
  {
    onMain: true,
    path: "/portfolio/case-study/ux",
    element: <UxCaseStudy />,
  },
  {
    onMain: true,
    path: "/portfolio/case-study/product-design/general",
    element: (
      <ProductDesignCaseStudy>
        <GeneralTab />
      </ProductDesignCaseStudy>
    ),
  },
  {
    path: "/portfolio/case-study/product-design/self-service-platform",
    element: (
      <ProductDesignCaseStudy>
        <SelfServicePlatformTab />
      </ProductDesignCaseStudy>
    ),
  },
  {
    path: "/portfolio/case-study/product-design/ios-app",
    element: (
      <ProductDesignCaseStudy>
        <IOSAppTab />
      </ProductDesignCaseStudy>
    ),
  },
  {
    path: "/portfolio/case-study/product-design/website",
    element: (
      <ProductDesignCaseStudy>
        <WebsiteTab />
      </ProductDesignCaseStudy>
    ),
  },
  {
    path: "/portfolio/case-study/product-design/web-apps",
    element: (
      <ProductDesignCaseStudy>
        <WebAppsTab />
      </ProductDesignCaseStudy>
    ),
  },
  {
    onMain: true,
    path: "/portfolio/case-study/ux-system",
    element: <UxSystemCaseStudy />,
  },
  {
    onMain: true,
    path: "/portfolio/case-study/presale-estimate",
    element: <PreSaleCaseStudy />,
  },
];

const router = createBrowserRouter([
  ...mainRoutes,
  ...caseStudyRoutes,
  {
    path: "/",
    element: <Navigate to="/portfolio" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/portfolio" replace />,
  },
]);

loadAssets().then(() => {
  const preloader = document.getElementById("preloader");
  const preloaderStyles = document.getElementById("preloader-styles");
  const preloaderProgress = document.getElementById("preloader-progress");

  const rootElement = document.getElementById("root") as HTMLElement;
  const root = ReactDOM.createRoot(rootElement);

  root.render(<RouterProvider router={router} />);

  preloader!.classList.add("yp-preloader--disappear");
  preloaderProgress!.classList.add("yp-preloader__progress--disappear");

  setTimeout(() => {
    preloader!.remove();
    preloaderStyles!.remove();
  }, 800);
});
