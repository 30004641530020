import { CaseStudyNavigation } from "components/case-study-navigation/case-study-navigation";

export function WebsiteTab() {
  return (
    <>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">The product overview</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          Websites are an integral part of any modern business, and our customer
          company, in their turn, decided to develop a business website. We’ve
          taken over the entire project, completing all the tasks from scratch.
          The main objectives were the representation of the idea and the
          product. Communicating the company’s core values, simplicity and
          openness to future business customers formed the basis of the work on
          the project.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          We decided to make not just a marketing site, but a site-instruction.
          Each page has to explain and show the simplification of business
          processes for future customers. Light and unobtrusive work approach
          helped us create a straightforward and involving web environment.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The basic structure of the site looks simple and understandable for
          both development and future clients:
        </p>
        <div className="yp-case-study--product-website-flow yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Wireframes</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          After creating the project specification, we proceeded to the
          wireframing stage with the solution architect. Simplicity remained the
          primary and essential point in implementing the project, so the basic
          skeleton of the site turned out to be clear, easy to understand, and
          neat.
        </p>
        <div className="yp-case-study--product-website-wireframes yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--product-website-wireframes-2" />
      </div>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">UI Design</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          A hallmark of the UI design stage was the requirement to build a
          design system around certain elements. Honeycombs as a design element
          have become the dominant message of the business. Each part of a vast
          structure turns the system into a working, efficient, and
          well-coordinated mechanism that brings a pronounced and long-term
          effect. Since we are dealing with a purely digital product, we have
          made a suitable color palette reflecting the globalization of the
          business through the product. Graphics, images, and icons were
          designed to enhance this effect.
        </p>
        <div className="yp-case-study--product-website-ui-tap yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--product-website-ui-tap-2" />
      </div>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">Design system</h2>
        <div className="yp-case-study--product-website-styleguide yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Linked prototype</h2>
        <div className="yp-case-study-text--margin-bottom-48">
          <div className="yp-case-study--product-website-prototype" />
        </div>
      </div>
      <CaseStudyNavigation
        prevLink="/portfolio/case-study/product-design/ios-app"
        nextLink="/portfolio/case-study/product-design/web-apps"
        prevLinkTitle="CASE IOS APP"
        nextLinkTitle="CASE WEB APPS"
      />
    </>
  );
}
