import { Layout } from "components/layout/layout";
import { CaseStudyBanner } from "components/case-study-bunner/case-study-banner";
import { CaseStudyNavigation } from "components/case-study-navigation/case-study-navigation";

import "./styles/case-study.css";

export function PreSaleCaseStudy() {
  return (
    <Layout background="light">
      <CaseStudyBanner name="preSale">
        <h1 className="yp-case-study-banner-head">Presale expertise</h1>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Industry:</span>{" "}
          multiple industries.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">The role:</span>{" "}
          presale design expert.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">
            Implementation time:
          </span>{" "}
          3 years.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Technology:</span>{" "}
          multiple technologies.
        </span>
        <span className="yp-case-study-banner-text">
          <span className="yp-case-study-banner-text--bold">Description:</span>{" "}
          the first stage of the customer and the company cooperation.
        </span>
      </CaseStudyBanner>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">The presale stage overview</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The presale stage is the initial phase of partnership with customers,
          where they are looking for the most suitable cooperation options and
          more favorable financial conditions and evaluating the team’s
          professionalism level.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          In turn, the company gets acquainted with the client, the project idea
          to bring to life, and offers the vision of the idea implementation.
          Here, an estimate is given in terms of the time to complete the entire
          task; the cost, limitations, and general conditions of cooperation are
          negotiated.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The successful completion of the presale stage is the signing of an
          agreement with the client and further cooperation.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-48">
          My area of responsibility in presale expertise is built around design
          activities. It implies full responsibility for clarifying the
          requirements, analyzing them, and preparing the estimate and the
          necessary artifacts.
        </p>
        <h2 className="yp-case-study-head">Estimation</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          While working on estimating the project, I solve the following tasks:
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Customers validation to understand their willingness to accept an
          estimate and work together on a project;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - An estimate creation in terms of time for the work amount that
          should be performed at the design stage;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Considering the side of development and selected technical
          solutions;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Giving a primary fragmentation of design stages: as a designer, I
          have to understand the steps needed on a particular project;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Development of a design strategy: a general strategy for developing
          a design for a specific project - in fact, it’s formed already at the
          fragmentation of design stages;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Considering the level of the designer who will participate in the
          project and choosing the best combination of team performers.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          In order to determine the type of estimate, I also had to define the
          error based on the initial requirements of the potential customer.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          Based on the percentage of errors, I made an estimate of 4 types: SR
          (Super Rough), ROM, Precise and Fixed. Super Rough and ROM estimate
          examples:
        </p>
        <div className="yp-case-study--presale-image-1 yp-case-study-text--margin-bottom-24" />
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          Precise and Fixed estimate examples:
        </p>
        <div className="yp-case-study--presale-image-2 yp-case-study-text--margin-bottom-24" />
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          Also, along with the calculation of time for design development, a
          table was added: it contains the areas of responsibility of
          specialists involved in the project or presale.
        </p>
        <div className="yp-case-study--presale-image-3 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Presale artifacts</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The artifacts of the presale process are determined by the needs and
          requirements of the future project. At the time of evaluating work
          scope, we are streamlining to immerse ourselves in the project as
          deeply as possible and understand it well. Therefore, the results of
          the work can be both block diagrams of the future flow design, and
          full-fledged wireframes, if necessary.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The most common expertise artifacts are: block diagrams, modular
          diagrams, section and screen diagrams, application architecture, list
          of basic functionality, a limited number of screens, wireframes, and
          Proof of Concept screens. Some examples of artifacts can be seen
          below:
        </p>
        <div className="yp-case-study--presale-image-4 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--presale-image-5 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--presale-image-6 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--presale-image-7 yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--presale-image-8 yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Summing up</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          Costly software projects and multifunctional products require a
          detailed study of offers from various suppliers. The clearer and more
          complicated the characteristics and principles of operation are, the
          easier it is to choose a well-designed option.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-48">
          <span className="yp-case-study-text--bold">Presale expertise</span> is
          one of the ways of my personal growth as a designer. We could carry
          out our tasks effectively with a team of presale experts from the R&D
          and Sales departments. Part of the work of each presale expert is
          strictly regulated in order not to create output confusion and avoid
          excess effort. The results of each expert’s contribution were
          necessarily collected in a typical document to see the whole picture
          of the upcoming project.
        </p>
      </div>
      <CaseStudyNavigation
        prevLink="/portfolio/case-study/ux-system"
        nextLink="/portfolio/case-study/product-design/general"
        prevLinkTitle="UX/UI CASE FOR FINANCIAL SYSTEM"
        nextLinkTitle="PRODUCT DESIGN CASE FOR STARTUP"
      />
    </Layout>
  );
}
