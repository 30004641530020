import { CaseStudyNavigation } from "components/case-study-navigation/case-study-navigation";

export function WebAppsTab() {
  return (
    <>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">The product overview</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          Small game applications are one of the essential and bulky parts of
          the entire product. The main task of this module is to provide the
          client with the ability to independently customize the interface that
          will be shown to end users within the business.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          It was crucial to easily allow the user to create an application
          interface without development and design skills. To cope with this
          task, we have created an environment in which the user can select the
          desired application type from presets stored in a common self-service
          platform.
        </p>

        <p className="yp-case-study-text yp-case-study-text--margin-bottom-48">
          As part of the MVP project, we created and unified the structure of
          mini-applications that will be displayed by tapping on a tag.
        </p>
        <h2 className="yp-case-study-head">Wireframes</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The simplicity and linearity of future mini-applications became
          apparent after working through the wireframe stage. Several basic game
          apps have a simple step-by-step architecture. Light, entertaining,
          gamified applications will help the end users solve their problems and
          should add additional business value to the user through winnings,
          gifts, pleasant surprises, and, in the end, a high trust level.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The basic mini-games list includes «spin the wheel», «treasure hunt»,
          «scratch-off», «quiz game», «questionnaire», and «assistant» game
          styles.
        </p>
        <div className="yp-case-study--product-web-apps-wireframes yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">Styleguide and design systems</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The atypicality of the task initially broke the traditional system of
          work of the designer-developer bundle. However, once we have
          identified the primary essential connections of linear games and the
          significance of the design system in this subproject, we decided to
          unify the base styles to fill the ecosystem base with default presets
          of pictures, images, icons, and fonts. The core style guide sets end
          up looking this way:
        </p>
        <div className="yp-case-study--product-web-apps-catalog yp-case-study-text--margin-bottom-48" />
        <h2 className="yp-case-study-head">UI Design</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The purpose of small web applications is to entertain users and
          activate a wow factor. Small games (created within the ecosystem)
          serve as an additional communication chain between the user and the
          business. The visual interface’s main task is not to be tied to a
          typical style and design system. The user must choose their styles,
          allowing B2B businesses to communicate their values. The white-label
          solution, in this case, is perfect for end users and discreetly solves
          problems in a win-win mode.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          Here are some examples of visual design of applications that are
          included in the self-service platform as default solutions:
        </p>
        <div className="yp-case-study--product-web-apps-ui-1" />
        <div className="yp-case-study--product-web-apps-ui-2" />
        <div className="yp-case-study--product-web-apps-ui-3" />
        <div className="yp-case-study--product-web-apps-ui-4" />
        <div className="yp-case-study--product-web-apps-ui-5" />
        <div className="yp-case-study--product-web-apps-ui-6" />
        <div className="yp-case-study--product-web-apps-ui-7" />
        <div className="yp-case-study--product-web-apps-ui-8 yp-case-study-text--margin-bottom-48" />
      </div>
      <CaseStudyNavigation
        prevLink="/portfolio/case-study/product-design/website"
        nextLink="/portfolio/case-study/ux-ui"
        prevLinkTitle="CASE WEBSITE"
        nextLinkTitle="UX/UI CASE FOR SPORTS APP"
      />
    </>
  );
}
