import { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";

import "./case-study-navigation.css";

type CaseStudyNavigationProps = {
  prevLink: string;
  nextLink: string;
  prevLinkTitle: string;
  nextLinkTitle: string;
};

export function CaseStudyNavigation({
  prevLink,
  nextLink,
  prevLinkTitle,
  nextLinkTitle,
}: CaseStudyNavigationProps) {
  const onBackOnTop = useCallback(() => {
    window.scrollTo({ top: -1, behavior: "smooth" });
  }, []);

  useEffect(() => {
    onBackOnTop();
  }, [onBackOnTop]);

  return (
    <div className="yp-case-study-navigation">
      <Link
        className="yp-case-study-navigation-item yp-case-study-navigation-item--align-right"
        to={prevLink}
      >
        <span className="yp-case-study-navigation-item-arrow yp-case-study-navigation-item-arrow--left" />
        <span className="yp-case-study-navigation-item-title">
          {prevLinkTitle}
        </span>
      </Link>
      <div
        className="yp-case-study-navigation-item yp-case-study-navigation-item--align-center yp-case-study-navigation-item--up"
        onClick={onBackOnTop}
      >
        <span className="yp-case-study-navigation-item-arrow yp-case-study-navigation-item-arrow--up" />
        <span className="yp-case-study-navigation-item-title">back on top</span>
      </div>
      <Link
        className="yp-case-study-navigation-item yp-case-study-navigation-item--align-left"
        to={nextLink}
      >
        <span className="yp-case-study-navigation-item-arrow yp-case-study-navigation-item-arrow--right" />
        <span className="yp-case-study-navigation-item-title">
          {nextLinkTitle}
        </span>
      </Link>
    </div>
  );
}
