import { CaseStudyNavigation } from "components/case-study-navigation/case-study-navigation";

export function SelfServicePlatformTab() {
  return (
    <>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">The product overview</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The self-service platform is a system that streamlines information
          from tags to mobile applications on users’ phones. A market analysis
          showed no analogs or similar products since technical administration
          and end-user services are usually different systems, applications, and
          platforms. The main goal of the self-service platform lies in
          implementing the complete digital cycle and the product delivery from
          the factory to the end user.
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          The main implementation path is:
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Loading pallets with tags into the system using a mobile
          application;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Marking tags and distributing them according to the necessary
          business tasks;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-12">
          - Creating a User Experience that will be shown to the end user;
        </p>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-48">
          - Linking tags to user experience applications.
        </p>
        <h2 className="yp-case-study-head">Wireframes</h2>
        <div className="yp-case-study--product-adjusment" />
      </div>
      <div className="yp-case-study yp-case-study-text--margin-top-24">
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The platform was wireframed in several stages. The first and foremost
          stage was structuring the system’s core features, creating the basic
          MVP of the product, to build further sprints of work on the system to
          increase functionality. The main task at each stage of the work was to
          keep up with the requirements of the end users, with whom the business
          owners interacted on an ongoing basis. The development team processed
          new insights weekly and turned them into requirements for the project.
        </p>
        <div className="yp-case-study--product-wireframes yp-case-study-text--margin-bottom-24" />
      </div>
      <div className="yp-case-study">
        <div className="yp-case-study--product-wr-tap" />
      </div>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">UI Design</h2>
        <p className="yp-case-study-text yp-case-study-text--margin-bottom-24">
          The project’s design stage assumed a visual connection between the
          main business website and the ecosystem. Colors and basic design
          elements were designed to unite the systems so that the user has the
          feeling of one common business field. Since the goals and objectives
          of the two platforms were different, the task was carried out to unify
          the system design. In this case, the UI design of the self-service
          platform becomes non-intrusive, convenient, and does not distract
          users from solving their problems — but is recognizable, bright, and
          attractive simultaneously.
        </p>
        <div className="yp-case-study--product-banner" />
        <div className="yp-case-study--product-ui-tap yp-case-study-text--margin-bottom-24" />
        <div className="yp-case-study--product-ui-screens" />
      </div>
      <div className="yp-case-study yp-case-study-text--margin-top-24">
        <h2 className="yp-case-study-head">Design system</h2>
        <div className="yp-case-study--product-design-system" />
      </div>
      <div className="yp-case-study">
        <h2 className="yp-case-study-head">Linked prototype</h2>
        <div className="yp-case-study--product-prototype" />
        <div className="yp-case-study--product-banner-macbook yp-case-study-text--margin-bottom-48" />
      </div>
      <CaseStudyNavigation
        prevLink="/portfolio/case-study/product-design/general"
        nextLink="/portfolio/case-study/product-design/ios-app"
        prevLinkTitle="PRODUCT DESIGN CASE FOR STARTUP"
        nextLinkTitle="CASE IOS APP"
      />
    </>
  );
}
