import { Layout } from "components/layout/layout";

import "./styles/cv.css";

export function CV() {
  return (
    <Layout background="dark">
      <div className="yp-cv">
        <div className="yp-cv-left-column yp-design-image-cubes--cv-page yp-design-image-cubes">
          <div className="yp-cv-photo" />
          <h2 className="yp-cv-head yp-cv--top-margin-40">
            Technology skills and proficient tools
          </h2>
          <p className="yp-cv-text">
            Adobe Xd, Figma, Adobe Photoshop, Adobe Illustrator, inVision,
            Zepplin, Confluence, Jira, Trello, business analysis skills
            (essential).
          </p>
          <div className="yp-cv-divider" />
          <h2 className="yp-cv-head">Education</h2>
          <p className="yp-cv-text yp-cv-text--bullet">
            <span className="yp-cv--bold-text">2010 - 2012</span> - Master’s
            Degree, Simon Kuznets Kharkiv National University of Economics,
            Ukraine;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet">
            <span className="yp-cv--bold-text">2006 - 2010</span> - Bachelor’s
            Degree, Simon Kuznets Kharkiv National University of Economics,
            Ukraine.
          </p>
          <div className="yp-cv-divider" />
          <h2 className="yp-cv-head">Courses</h2>
          <p className="yp-cv-text yp-cv-text--bullet">
            <span className="yp-cv--bold-text">February 2018 - May 2018</span> -
            Bang Bang education school, Design management course by Dmitriy
            Karpov;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet">
            <span className="yp-cv--bold-text">
              October 2015 - February 2016
            </span>{" "}
            - Spalah IT school, UI/UX design courses, and advanced Adobe
            Illustrator courses (Kharkiv, Ukraine);
          </p>
          <p className="yp-cv-text yp-cv-text--bullet">
            <span className="yp-cv--bold-text">May 2015 - September 2015</span>{" "}
            - Source IT school, Advanced Web design courses, accreditation ID
            09/15-1091 (Kharkiv, Ukraine);
          </p>
          <p className="yp-cv-text yp-cv-text--bullet">
            <span className="yp-cv--bold-text">
              September 2011 - January 2012
            </span>{" "}
            - Simon Kuznets Kharkiv National University of economics, Business
            negotiations and time management.
          </p>
          <div className="yp-cv-divider" />
          <h2 className="yp-cv-head">Languages</h2>
          <p className="yp-cv-text yp-cv-text--bullet">
            <span className="yp-cv--bold-text">English</span> – B2
            (upper-intermediate);
          </p>
          <p className="yp-cv-text yp-cv-text--bullet">
            <span className="yp-cv--bold-text">Ukrainian</span> – native;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet">
            <span className="yp-cv--bold-text">Russian</span> – native.
          </p>
          <div className="yp-cv-divider" />
          <h2 className="yp-cv-head">Personality traits</h2>
          <p className="yp-cv-text">
            Responsibility, empathy, strong communication and problem-solving
            skills, attention to detail and diligence, aspiration for continuous
            professional growth, decisiveness. Multitasking, result-oriented,
            self-motivated person with a sense of humor.
          </p>
        </div>
        <div className="yp-cv-right-column">
          <h2 className="yp-cv-head">Key information</h2>
          <p className="yp-cv-text">
            Senior UX/UI designer. 7 years of experience and 30+ projects: B2B
            and B2C systems, admin panels, ERP systems, mobile apps (iOS,
            Android, cross-platform), desktop apps, websites, design systems,
            responsive, landing and marketing materials, branding.
          </p>
          <p className="yp-cv-text yp-cv--top-margin-24">
            Worked on projects in domains: Healthcare, FinTech, Enterprise,
            Social Media, Real Estate, Logistics, Touchless experience, Tag/RFID
            and other industry ecosystems.
          </p>
          <p className="yp-cv-text  yp-cv--top-margin-24 yp-cv-text--bullet">
            phone number:{" "}
            <span className="yp-cv--bold-text">+372 5395 2897</span>
          </p>
          <p className="yp-cv-text yp-cv-text--bullet">
            e-mail:{" "}
            <a
              className="yp-cv-link yp-cv-text yp-cv--bold-text"
              href="mailto:yyanvar@gmail.com"
            >
              yyanvar@gmail.com
            </a>
          </p>
          <p className="yp-cv-text yp-cv-text--bullet">
            skype:{" "}
            <a
              className="yp-cv-link yp-cv-text yp-cv--bold-text"
              href="skype:live:.cid.9ad895db4ed4b880?chat"
            >
              live:.cid.9ad895db4ed4b880
            </a>
          </p>
          <p className="yp-cv-text yp-cv-text--bullet">
            linkedin:{" "}
            <a
              className="yp-cv-link yp-cv-text yp-cv--bold-text"
              href="https://linkedin.com/in/yana-bicheva-0357a19b"
              target="_blank"
            >
              linkedin.com/in/yana-bicheva-0357a19b
            </a>
          </p>
          <div className="yp-cv-divider" />
          <h2 className="yp-cv-head">Professional experience</h2>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv--line-height-32">
            <span className="yp-cv--bold-text">February 2023 - now</span> –
            UX/UI Designer, birkle IT Estonia OÜ
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv--line-height-32">
            <span className="yp-cv--bold-text">May 2016 - October 2021</span> –
            UX/UI Designer, Product Designer at MobiDev (Ukraine):
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Creating full-fledged design solutions for web apps, mobile apps
            (native & cross-platform), websites (responsive & adaptive),
            enterprise systems;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Design audit, redesigning of existing products, and writing
            recommendations for improvements (based on best practices);
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Detailed time estimation, direct communication with stakeholders;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            BA-thinking approach while processing requirements, presenting
            design solutions;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Mentoring (about 1,5 years);
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Interviewing (as a technical expert, about 1,5 years);
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Pre-sale activities;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Design estimation;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Design management (onboarding, guides, and design-articles writing).
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv--line-height-32">
            <span className="yp-cv--bold-text">
              February 2016 - September 2016
            </span>{" "}
            - Web Design teacher for Beginners at Source IT school (Kharkiv,
            Ukraine):
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Teaching program development;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Students teaching;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Students consultations;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Rating and evaluation of student’s knowledge;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Students examination;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Preparing students for technical interviews.
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv--line-height-32">
            <span className="yp-cv--bold-text">February 2014 - May 2016</span> -
            Web designer at Pabowl startup (Ukraine):
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            User interface design across web devices;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Responsive web design;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            UI style guides preparation;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Clarification of requirements and estimation of the design stage of
            the project;
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv-text--bullet-check yp-cv--line-height-32">
            Graphic, marketing materials, and branding preparation.
          </p>
          <p className="yp-cv-text yp-cv-text--bullet yp-cv--line-height-32">
            <span className="yp-cv--bold-text">April 2012 - December 2013</span>{" "}
            - Assistant at Department of Marketing and Corporate Communication
            at Simon Kuznets Kharkiv National University of Economics (Ukraine).
          </p>
        </div>
      </div>
    </Layout>
  );
}
