import { ReactNode } from "react";
import { Header } from "components/header/header";
import { Footer } from "components/footer/footer";

import "./layout.css";

type LayoutProps = {
  background: "light" | "dark";
  children: ReactNode | ReactNode[];
};

export function Layout({ background, children }: LayoutProps) {
  return (
    <div className={`yp-layout yp-layout--${background}`}>
      <Header />
      <main className="yp-layout-body">{children}</main>
      <Footer />
    </div>
  );
}
