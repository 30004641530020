import "./banner.css";

type BannerProps = {
  name: "portfolio" | "art" | "activities" | "about";
  children: string;
};

export function Banner({ name, children }: BannerProps) {
  return (
    <div className={`yp-banner yp-banner--${name}`}>
      <h1 className="yp-banner-head">{children}</h1>
    </div>
  );
}
